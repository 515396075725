<template>
	<div class="news_banner-1">
		<div class="news_banner">
			<img :src="banner[0].bannerImg" alt="" width="100%" />
		</div>
		<div class="aa1-3-5-1" style="background-color: #ffffff;">
			<!-- 融合视觉传感芯片 -->{{$t('goods.Hybrid Vision Sensor')}}
		</div>
		<el-row :gutter="20" type="flex" class="goods">
			<div class="bjys" v-for="(item, index) in list" :key="item.id">
				<el-col :span="16" :offset="0">
					<div class="hebing">
						<div class="aa1-3-5">{{ item.productName }}</div>
						<img :src="item.productImg" alt="" width="50%" />
						<div class="bjys-1">
							{{ item.productTextDetail }}
							<!-- ALPIX-Titlis<span>™</span>融合式超低功耗视觉传感器 -->
						</div>
						<div class="bjys-2">
							{{ item.productDescription }}
							<!-- 适用于多种VIoT场景,保持对场景连续感知的同时,降低系统功耗。像素端过滤80%冗余信息,节省系统成本,增加事件流数据，提高算法精度与效率,适配现有图像系统。 -->
						</div>
						<div class="bjys-4">
							<!-- <div class="bjys-41" :class="{ bjys111: csxqs == index}" :style="{'--dheight': '500px'}"
								v-html="item.productSpecification"></div> -->

							<!-- <div class="cpxqk"> -->
							<div class="bjys-41" :class="{ bjys111: csxqs == index}" v-html="item.productSpecification">
							</div>
							<!-- </div>	 -->

							<div class="bjys-4-1">
								<i class="el-icon-arrow-down" :class="{ bjys11: csxqs == index }"
									@click="csxq(index)"></i>
							</div>
							<div class="bjys-4-2">
								<span class="bjys-4-2-1" @click="csxq(index)">
									<!-- 参数详情 -->{{ $t('goods.Specifications') }}
								</span>
							</div>
						</div>
						<div class="bjys-3">
							<div class="bjys-3-2" @click="purchase(item)">
								<!-- 购买 -->{{$t('goods.Order')}}
							</div>
						</div>
					</div>
				</el-col>
			</div>

			<el-col :span="16" :offset="0">
				<div class="hebing">
					<!-- <div class="aa1-3-5">解决方案</div> -->
					<div class="aa1-3-5-1">
						<!-- 解决方案 -->{{$t('goods.Solutions')}}
					</div>
					<div>
						<div v-for="item,index in cplist" :key="item.id">
							<div class="hebing-item" v-if="(index%2)===0" :style="{'justify-content': 'flex-start'}">
								<div class="hebing-item-img"><img :src="item.articleCovers" alt="" style="width: 100%;"></div>
								<div class="hebing-item-text" :id="item.articleTitle">
									<div class="hebing-item-text-title">{{ item.articleTitle }}</div>
									<div class="hebing-item-text-content" v-html="item.articleContents"></div>
								</div>
							</div>

							<div class="hebing-item" v-else :style="{'justify-content': 'flex-end'}">
								<div class="hebing-item-text" :id="item.articleTitle">
									<div class="hebing-item-text-title">{{ item.articleTitle }}</div>
									<div class="hebing-item-text-content" v-html="item.articleContents"></div>
								</div>
								<div class="hebing-item-img"><img :src="item.articleCovers" alt="" style="width: 100%;"></div>
							</div>


						</div>
					</div>

					<el-row class="tb-el-row">
						<el-col :span="8" :offset="0" class="bs00" v-for="item in cplist" :key="item.id">
							<div class="chufa-1">
								<div class="bs0">
									<img :src="item.articleCovers" alt="" width="100%" />
									<div class="zzc"></div>
								</div>
								<div class="bs1-1">{{ item.articleTitle }}</div>
								<div class="bs1-2" v-html="item.articleContents">
									<!-- 1.提供基于ALPIX<span>™</span>传感器解决方案，根据需求定制适合客户使用场景和平台的SDK，包含算法、驱动与用例;
                  <br />
                  2.帮助客户快速、全面应用ALPIX<span>™</span>系列产品，提成产品性能，缩短研发周期。 -->
								</div>
							</div>
						</el-col>
						<!-- <el-col :span="8" :offset="0" class="bs00">
              <div class="chufa-1">
                <div class="bs0">
                  <img src="../assets/images/a35.png" alt="" width="100%" />
                  <div class="zzc"></div>
                </div>
                <div class="bs1-1">参考设计</div>
                <div class="bs1-2">
                  基于ALPIX<span>™</span>传感器解决方案，结合开发工具库，构建快速开发及测试平台，为客户开发自由系统提供参考，包括:
                  <br />
                  1.MCU，CPU，NPU,GPU在内的定制化视觉系统;
                  <br />
                  2.USB，SPI,Bluetooth, WiFi，Ethernet，MIPI接口模块;…
                </div>
              </div>
            </el-col>
            <el-col :span="8" :offset="0" class="bs00">
              <div class="chufa-1">
                <div class="bs0">
                  <img src="../assets/images/a36.png" alt="" width="100%" />
                  <div class="zzc"></div>
                </div>
                <div class="bs1-1">视觉模组</div>
                <div class="bs1-2">
                  提供定制化的芯片、光学、PCB、软件、封装、测试等全套视觉模块,助力客户的视觉方案从像素层到系统层全面领先。
                </div>
              </div>
            </el-col> -->
					</el-row>
					<div class="bjys-3-2-b" @click="purchase(item)">
						<!-- 购买 -->{{$t('goods.Order')}}
					</div>
				</div>



				<!-- <el-dialog title="提交表格" :visible.sync="dialogFormVisible" width="50%" center class="eldialog"> -->
				<el-dialog title="提交表格" :visible.sync="dialogFormVisible" center class="eldialog">
					<div slot="title">
						<div class="dialogtitle">
							<!-- 提交表格，</br>我们将根据您的需求尽快与您取得联系： -->{{$t('goods.Please submit your details and we will contact you:')}}
						</div>
					</div>

					<div class="form-item">
						<div class="form-lable">*
							<!-- 姓名 -->{{$t('goods.Name')}}
						</div>
						<input v-model="form.name"></input>
					</div>
					<div class="form-item">
						<div class="form-lable">*
							<!-- 邮箱 -->{{$t('goods.E-mail')}}
						</div>
						<input v-model="form.email"></input>
					</div>
					<div class="form-item">
						<div class="form-lable">
							<!-- *电话 -->{{$t('goods.Phone No.')}}
						</div>
						<input v-model="form.phone"></input>
					</div>
					<div class="form-item">
						<div class="form-lable">*
							<!-- 工作头衔 -->{{$t('goods.Title')}}
						</div>
						<input v-model="form.title"></input>
					</div>
					<div class="form-item">
						<div class="form-lable">*
							<!-- 公司 -->{{$t('goods.Company')}}
						</div>
						<input v-model="form.company"></input>
					</div>
					<div class="form-item">
						<div class="form-lable">*
							<!-- 国家／地区 -->{{$t('goods.Location')}}
						</div>
						<el-select placeholder=" " v-model="form.country">
							<el-option v-for="item in countrylist" :label="language === 'cn' ? item.languageCn: item.languageEn" :value="language === 'cn' ? item.languageCn: item.languageEn"
								:key="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="form-item">
						<div class="form-lable">*
							<!-- 行业 -->{{$t('goods.Industry')}}
						</div>
						<!-- <input ></input> -->
						<el-select placeholder=" " v-model="form.industry">
							<el-option v-for="item in hangyelist" :label="language === 'cn' ? item.languageCn: item.languageEn" :value="language === 'cn' ? item.languageCn: item.languageEn"
								:key="item.id">
							</el-option>
						</el-select>
					</div>

					<!-- <div class="form-item"> -->
					<textarea :placeholder="$t('goods.Your requirements')" v-model="form.remark"></textarea>
					<!-- </div> -->


					<!-- <div class="check_content ">
            <div class="check_box">
              <i class="el-icon-check"></i>
            </div>
            <div class="check_info">我想订阅锐思智芯的最新动态</div>
          </div> -->

					<div class="submit_info" @click="submit">
						<!-- 提交 -->{{$t('goods.SUBMIT')}}
					</div>
				</el-dialog>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			// title: "产品速览", // set a title
			meta: [{
					// set meta
					name: "keyWords",
					content: "锐思",
				},
				{
					name: "description",
					content: "我是description描述",
				},
			],
			link: [{
				// set link
				rel: "asstes",
				href: "www.baidu.com",
			}, ],
		},
		data() {
			return {
				dialogFormVisible: false,
				banner: [{
					bannerImg: "",
				}, ],
				form: {
					name: "",
					email: "",
					title: "",
					company: "",
					industry: ""
				},
				csxqs: "999",
				cplist: "",
				list: "",
				checkitem: {},
				countrylist: [], //国家下拉选项列表
				hangyelist: [] //行业下拉选线列表
			};
		},
		created() {	
			window.document.title = this.$t('goods.title')
			this.getBanner();
			this.getcplist();
			this.getlist();
		},
		computed: {
			language() {
				return this.$store.state.Language
			}
		},
		watch: {
			language(newVal, oldVal) {
				window.document.title = this.$t('goods.title')
				this.getcplist() //获取解决方案
				this.getBanner()
				this.getlist().then(()=>{ //获取芯片
					this.puthSpan(this.csxqs)
				})
			}
		},
		mounted() {
			this.countrylist = require('../assets/location/location.json') //地区列表数据
			this.hangyelist = require('../assets/location/industry.json') //行业列表
			this.$nextTick(()=>{
				setTimeout(function() {
					let toId = window.sessionStorage.getItem('toId')
					let elId = document.getElementById(toId)
					if(elId){
						elId.scrollIntoView();
					}
				}, 50);
			})
		},
		destroyed(){
			window.sessionStorage.removeItem('toId')
		},
		methods: {
			submit() {
				if (this.form.name == '') {
					this.$message({
						message: '姓名不能为空！',
						type: 'warning'
					});
					return
				}
				var reg = /(^([\.a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+)|(^1[3456789]\d{9}$)/
				if (this.form.email == '' && reg.test(this.form.email)) {
					this.$message({
						message: '邮箱为空或格式不正确！',
						type: 'warning'
					});
					return
				}
				if (this.form.title == '') {
					this.$message({
						message: '职位不能为空！',
						type: 'warning'
					});
					return
				}
				if (this.form.company == '') {
					this.$message({
						message: '公司不能为空！',
						type: 'warning'
					});
					return
				}
				if (this.form.industry == '') {
					this.$message({
						message: '行业不能为空！',
						type: 'warning'
					});
					return
				}
				this.$http.post('/other/question',{
					phone: this.form.phone,
					content: this.form.remark,
					name: this.form.name,
					email: this.form.email,
					company: this.form.company,
					job: this.form.title,
					address: this.form.country,
					industry: this.form.industry
				}).then(res=>{
					console.log('成功',res)
          this.dialogFormVisible = false
				})
        console.log('提交到后台')

				var url = `mailto:talent@alpsentek.com?subject=${this.checkitem.productName}&body=
      姓名：${this.form.name || ''}
      邮箱：${this.form.email || ''}
      电话：${this.form.phone || ''}
      工作头衔：${this.form.title || ''}
      公司：${this.form.company || ''}
      国家/地区：${this.form.country || ''}
      行业：${this.form.industry || ''}
      需求：${this.form.remark || ''}
      `
				window.location.href = url


			},
			purchase(item) {
				console.log('购买产品',item)
				this.checkitem = item
				this.dialogFormVisible = true
			},
			getBanner() {
				let language = this.$store.state.Language
				this.$http.get("/appBanner/getBannerList?spaceId=11&language="+language).then((res) => {
					this.banner = res.data;
					console.log(this.banner);
				});
			},

			getcplist() { //获取解决方案
				let language = this.$store.state.Language
				this.$http.get("/newList/list?parentId=11&language=" + language).then((res) => {
					console.log(res)
					this.cplist = res.rows.slice(1);
					console.log(this.cplist);
				});
			},

			async getlist() { //获取芯片信息
				let language = this.$store.state.Language
				let res = await this.$http.get("appProduct/getProductList?language=" + language)
				console.log(res)
				this.list = res.data;
				// this.$http.get("appProduct/getProductList?language=" + language).then((res) => {
				// 	console.log(res)
				// 	this.list = res.data;
				// });
			},
			puthSpan(index){
				console.log(index)
				let t = document.getElementsByClassName('bjys-41')[index].childNodes
				let span = document.createElement('span')
				// let arr = Array.prototype.slice.call(t.childNodes);
				t.forEach(item=>{
					item.innerHTML = `<span></span><p>${item.textContent}</p>`
				})
				console.log('22222')
			},
			csxq(imdex) {
				if (this.csxqs == imdex) { //收缩
					this.csxqs = 999;
				} else { //展开
					this.puthSpan(imdex)
				// -------------------------------------------------------------

					this.csxqs = imdex;
					setTimeout(() => {
						// let t = this.$refs.cpxq[imdex]
						let t = document.getElementsByClassName('bjys-41')[imdex]
						let arr = Array.prototype.slice.call(t.childNodes); //NodeList 转数组
						let cont = 0
						arr.forEach(item => {
							cont += item.clientHeight
						})
						t.style = {
							"--dheight": cont + "px"
						}
					}, 100);

				}
			},

			navToShop(item) {
				this.$router.push({
					path: "/products",
					query: {
						productId: item.productId
					},
				});
			},
		},
	};
</script>

<style lang="scss">
	.hebing-item {
		width: 100%;
		height: auto;
		margin: 35px 10px;
		display: flex;
		// background-color: #ffffff;
		border-radius: 3px;
		.hebing-item-img{
			width: 50%;
			overflow: hidden;
		}
	}

	@media screen and (min-width:1000px) {
		.tb-el-row {
			display: none;
		}
	}

	@media screen and (max-width: 1000px) {
		.hebing-item {
			display: none;
		}
	}

	.hebing-item-text {
		width: 100%;
		padding: 15px;
		text-align: left;
	}

	.hebing-item-text-title {
		color: #000;
		font-size: 24px;
		line-height: 30px;
		font-weight: 500;
	}

	.bjys-3-2-b {
		display: inline-block;
		width: 155px;
		height: 40px;
		background-color: rgb(35, 80, 199);
		line-height: 36px;
		font-size: 18px;
		text-align: center;
		border-radius: 5px;
		margin: 40px auto;
		color: #ffffff;
		box-sizing: border-box;
		border: 2px solid rgb(35, 80, 199);
		transition: 0.5s;
	}

	.bjys-3-2-b:hover {
		color: rgb(35, 80, 199) !important;
		background-color: rgb(255, 255, 255) !important;
		transition: 0.5s;
		cursor: pointer;
	}

	.cpxqk {
		height: 0px;
		overflow: hidden;
		transition: all 0.5s;
	}

	.cpxqk-k {
		height: var(--dheight);
		transition: all 1s;
	}

	.bjys-41 {
		width: 80%;
		margin: auto;
		height: 0px;
		transition: 0.5s;
		overflow: hidden;

		>p {
			span{
				padding: 0px 2px;
				display: block;
				height: 15px;
				background-color: #7aa5de;
				border-radius: 20px;
				margin: 0px 10px;
				margin-top: 19px;
			}
			float: left;
			display: none;
			width: 50%;
			// height: 30px;
			text-align: left;
			display: flex !important;
			justify-content: flex-start;
			// display: inline-block;
		}
	}

	// $dheight: 625px;
	$dheight: var(--dheight);

	.bjys111 {
		// height: var(--dheight);
		// transition: var(--transition);
		height: $dheight;
		transition: 1s;



		p {
			display: inline-block;
			transition: 0.5s;
		}
	}
</style>

<style lang="scss" scoped>
	.el-icon-arrow-down {
		transform: rotate(0deg);
		transition: 0.5s;
		font-weight: 700;
	}
	.el-icon-arrow-down:hover{
		cursor: pointer;
	}
	.bjys11 {
		transform: rotate(180deg);
		transition: 0.5s;
	}


	.news_banner {
		// background: url(../assets/images/cptou.jpg) no-repeat;
		// background-size: 100% 100%;
		// height: 100%;
		// display: flex;
		// justify-content: flex-start;
		align-items: center;
		text-align: center;
		width: 100%;

		p {
			padding-left: 100px;
		}
	}

	.news_banner-1 {
		background-color: rgb(245, 245, 247);
		padding: 0px;
		// overflow: inherit;
	}

	.rs0 {
		height: 300px;
	}

	.rs1 {
		width: 100%;
		text-align: center;
		font-size: 65px;
		// margin-top: 310px;
		font-weight: 500;
		color: #fff;
	}

	.rs2 {
		width: 100%;
		text-align: center;
		font-size: 45px;
		margin-top: 0px;
		font-weight: 500;
		color: #fff;
		position: relative;
		top: -5px;
	}

	.goods_title_ {
		// background: url(http://www.dhs-sports.com/UpLoadFile/20180723/98e36ec5-c87c-4b1a-9bea-052eeacfd185.png) no-repeat;
		background: url(../assets/images/121.png) no-repeat;
		background-size: 100% 100%;
		height: 758px;
		// display: flex;
		// justify-content: flex-start;
		align-items: center;
		text-align: center;
		width: 100%;

		p {
			padding-left: 100px;
		}
	}

	.goods {
		flex-direction: column;
		align-items: center;
		border-radius: 8px;
		//   border: 1px solid #ccc;
	}

	.lv-one-list {
		// border: 1px solid #ccc;
		display: flex;
		//   padding: 30px;
		justify-content: center;
		align-items: center;

		.one-list {
			color: #000;
			flex: 1;
			display: flex;
			justify-content: center;
			margin: 10px;
			padding: 30px 0px;
			// border: 1px solid #ccc;
			border-radius: 8px;
			// box-shadow: 0px 2px 2px #ccc;
			// padding: 20px;
			// width: 200%;
			// justify-content: center;
			flex: 1;
			flex-wrap: wrap;

			div {
				// flex: 1;
				padding: 5px;
				margin: 10px;
				// border: 1px solid #ccc;
				background-color: #fff;
				border-radius: 8px;
				// max-width: 30%;
				// width: 100%;
				display: flex;
				flex-wrap: wrap;
				transition: 0.5s;

				span {
					// flex: 1;
					// width: 12.5%;
					padding: 12px 20px;
					font-weight: 900;
					font-size: 14px;
					// color: #000;
					cursor: pointer;
				}
			}
		}
	}

	.one-list div:hover {
		color: rgb(255, 255, 255) !important;
		background-color: rgb(34, 81, 197) !important;
		transition: 0.5s;
	}

	.act {
		color: rgb(255, 255, 255) !important;
		background-color: rgb(34, 81, 197) !important;
	}

	.lv-two-list {
		display: flex;
		justify-content: center;
		// align-content: center;
		text-align: center;
		flex-wrap: wrap;
		padding: 20px 0;

		span {
			// flex: 1;
			font-size: 20px;
			font-weight: 500;
			padding: 0px 50px;
			position: relative;
			cursor: pointer;
		}
	}

	.checked::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 20%;
		bottom: -5px;
		right: 20%;
		background: rgb(34, 81, 197);
	}

	.product {
		display: flex;
		justify-content: center;
		cursor: pointer;
		//   flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-start;

		.product-1 {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.7);
			z-index: 99;
			transform: translateX(-100%);
			transition: transform 0.5s;
			color: #fff;
			box-sizing: border-box;
			padding: 5%;

			.product-1-1:hover {
				display: inline-block;
				width: 50%;
				// margin-top: 70px;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				color: rgb(34, 81, 197);
				transition: transform 0.5s;
			}

			.product-1-1 {
				display: inline-block;
				width: 50%;
				// margin-top: 70px;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				color: rgb(255, 255, 255);
				transition: transform 0.5s;
			}

			.product-1-0 {
				height: 250px;
			}
		}

		.product-shop:hover .product-1 {
			transform: translateX(0%);
			transition: transform 0.5s;
		}

		.product-shop {
			position: relative;
			border-radius: px;
			overflow: hidden;
			box-shadow: 0px 0px 10px #ccc;
			background-color: #fff;
			margin: 12.8px;
			width: 30%;
		}

		.product-img {
			width: 100%;
			height: 240px;
		}

		.product-img:hover {
			transform: scale(1.1);
			transition: 0.8s;
		}

		.product-name {
			padding: 16px 0px 16px 0px;
			text-align: center;
			font-size: 18px;
			color: rgb(125, 125, 125);
		}

		.product-detail {
			width: 80%;
			margin-left: 10%;
			padding-bottom: 15.6px;
			text-align: center;
			font-size: 14px;
			color: rgb(125, 125, 125);
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			overflow: hidden;
		}
	}

	.more-star {
		width: 90px;
		border-radius: 10px;
		padding: 5px 3px;
		margin: 52px auto 30px;
		cursor: default;
		font-size: 12px;
		background: #eee;
		text-align: center;
		color: #aaa;
	}

	.moreStarAct {
		background: rgb(196, 0, 10);
		color: #fff;
		cursor: pointer;
	}

	.productMenuImg {
		height: 50px;
		width: 50px;
		object-fit: cover;
		border-radius: 5%;
	}

	.hebing {
		text-align: center;
	}

	.aa1-3-5 {
		font-size: 24px;
		color: rgb(0, 0, 0);
		margin-top: 50px;
		margin-bottom: 35px;
	}

	.aa1-3-5-1 {
		// background-color: #ffffff;
		font-size: 30px;
		color: rgb(0, 0, 0);
		height: 85px;
		font-weight: 500;
		line-height: 85px;
		text-align: center;
		font-family: 'Source Han Sans Light','Source Han Sans', 'Source Han Sans Unicode', Geneva, Verdana, sans-serif !important;
		// margin-top: 50px;
		// margin-bottom: 35px;
	}

	.aa1-1-3-4 {
		color: rgb(147, 149, 148);
		margin-bottom: 50px;
		margin-top: 8px;
	}

	.chufa-1 {
		// background-color: aqua;
		margin: 5.5%;
		margin-bottom: 28px;
	}

	.bs0 {
		position: relative;
	}

	.zzc {
		background-color: rgba(255, 255, 255, 0);
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		transition: 0.5s;
	}

	.bs1-1 {
		position: relative;
		top: -4px;
		line-height: 135px;
		height: 120px;
		font-size: 20px;
		color: rgb(0, 0, 0);
		background-color: rgb(255, 255, 255);
		// margin-bottom: 135px;
		transition: 0.5s;
	}

	.bs1-2 {
		height: 330px;
		text-align: left;
		position: relative;
		padding: 25px;
		padding-bottom: 40px;
		line-height: 35px;
		top: -4px;
		font-size: 17px;
		color: rgb(102, 102, 102);
		background-color: rgb(255, 255, 255);
		// 
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 10;
		-webkit-box-orient: vertical;

		span {
			display: inline-block;
			// font-size: 12px;
			transform: scale(0.8, 0.8);
			position: relative;
			top: -2px;
			// left: 1px;
			margin-right: 5px;
		}
	}

	.dix {
		height: 1px;
		background-color: rgb(255, 255, 255);
	}

	.bjys {
		position: relative;
		top: -4px;
		background-color: rgb(255, 255, 255);
		width: 100%;
		display: flex;
		justify-content: center;

		.aa1-3-5 {
			margin-top: 55px;
		}

		.bjys-1 {
			text-align: left;
			color: rgb(0, 0, 0);
			font-size: 22px;
			padding-left: 20px;
			border-left: 8px solid rgb(35, 80, 198);
			margin-bottom: 7px;

			span {
				display: inline-block;
				// font-size: 12px;
				transform: scale(0.8, 0.8);
				position: relative;
				top: -2px;
				// left: 1px;
				margin-right: 5px;
			}
		}

		.bjys-2 {
			text-align: left;
			color: rgb(0, 0, 0);
			line-height: 35px;
		}

		.bjys-3 {
			text-align: center;
			color: rgb(255, 255, 255);
			margin: auto;
			width: 100%;
			margin-top: 40px;
			margin-bottom: 55px;
			transition: 0.5s;

			.bjys-3-1 {
				display: inline-block;
				width: 155px;
				height: 40px;
				background-color: rgb(35, 80, 199);
				line-height: 40px;
				font-size: 18px;
				text-align: center;
				border-radius: 5px;
				// margin-right: 30px;
				margin: auto;
				box-sizing: border-box;
				border: 2px solid rgb(35, 80, 199);
				transition: 0.5s;
			}

			.bjys-3-2 {
				display: inline-block;
				width: 155px;
				height: 40px;
				background-color: rgb(35, 80, 199);
				line-height: 36px;
				font-size: 18px;
				text-align: center;
				border-radius: 5px;
				margin: auto;
				box-sizing: border-box;
				border: 2px solid rgb(35, 80, 199);
				transition: 0.5s;
			}
		}

		.bjys-3-2:hover {
			color: rgb(35, 80, 199) !important;
			background-color: rgb(255, 255, 255) !important;
			transition: 0.5s;
			cursor: pointer;
		}

		.bjys-4 {
			width: 100%;
			margin-top: 30px;

			.bjys-4-1 {
				font-size: 25px;
				width: 100%;
				color: rgb(0, 0, 0);
			}

			.bjys-4-2 {
				width: 100%;
				// color: rgb(0, 0, 0);
				transition: 0.5s;
			}

			.bjys-4-2-1 {
				color: rgb(0, 0, 0);
				transition: 0.5s;
			}

			.bjys-4-2-1:hover {
				color: rgb(35, 80, 199);
				transition: 0.5s;
				cursor: pointer;
			}
		}
	}

	@media screen and(max-width:1000px) {
		.one-list {
			flex-wrap: wrap;
			padding: 0 !important;

			div {
				width: 46% !important;
				padding-left: 10px !important;

				span {
					width: 100%;
				}
			}
		}

		.lv-two-list {
			// flex-direction: column;
			flex-wrap: wrap;

			span {
				margin: 8px 0px;
				width: 49%;
				padding: 0;
			}
		}

		.product-shop {
			width: 100% !important;
		}

		.bs00 {
			width: 100%;
		}

		.bs1-2 {
			height: 100%;
		}

		.bjys-41 {
			width: 80%;

			p {
				width: 100%;
			}
		}

		.bjys111 {
			height: 320px;
		}
	}
</style>

<style lang="scss">
	@media screen and(max-width:1000px) {
		.bjys-41 {
			width: 80%;

			p {
				width: 100%;
			}
		}

		.bjys111 {
			// height: 550px;
			height: var(--dheight) !important;
		}
	}

	.el-dialog.el-dialog--center {
		width: 640px !important;
		padding: 40px 110px 32px !important;
		background: url('../assets/images/bg_form.7d761839.jpg');
		background-size: 100% 100%;
	}

	@media screen and (max-width: 640px) {
		.el-dialog.el-dialog--center {
			width: 90% !important;
			padding: 0px !important
		}

		.el-dialog .el-dialog__body {
			font-size: 12px !important;
		}
	}

	.dialogtitle {
		text-align: center;
		color: #101947;
		line-height: 36px;
		font-size: 17px;
		margin: 0 auto 16px;
		font-family: 'SourceHanSansCNRegular';
	}

	.form-item {
		margin: 0px auto 16px;
		display: flex;
		align-items: center;
		background: #fff;
		height: 56px;
		width: 100%;

		.form-lable {
			width: 90px;
			margin-left: 10px;
			color: #101947;
		}

		input {
			// width: 250px;
			width: 85%;
			border: 0px;
			margin: 0 10px;
			outline: none;
			border: none;
			color: #101947;
		}


	}

	textarea {
		// width: 350px;
		width: 100%;
		height: 108px;
		outline: none;
		border: none;
		color: #101947;
		resize: none;
		// padding: 10px;
	}

	// @media screen and (max-width: 500px){
	// 	textarea {
	// 	  width: 90%;
	// 	}
	// }

	// @media screen and (max-width: 750px){
	// 	.eldialog{
	// 		width: 80%;
	// 	}
	// }

	.check_content {
		margin: 20px auto 30px auto !important;
		display: flex;
		align-items: flex-start;
		color: #101947;
		cursor: pointer;

		.check_box {
			width: 16px;
			height: 16px;
			display: flex;
			align-items: center;
			justify-items: center;
			border: 1px solid #101947;
			margin: 4px 5px 4px 0;

			i {
				font-size: 12px;
				color: rgb(15, 28, 73);
				margin-left: 1px;
			}
		}

		.check_info {
			flex: 1;
			line-height: 24px;
		}
	}

	.submit_info {
		width: 150px;
		height: 40px;

		border: 1px solid #7aa5de;
		margin: 0 auto;
		margin-top: 30px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		color: #000;
		font-size: 14px;
		letter-spacing: 1.2px;
	}

	.el-select {
		width: 90%;
		margin-left: 0%;
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-input__inner {
		border: none !important;
		box-shadow: none;
	}
</style>
